import React, { useMemo } from 'react'

import RecordCancelScheduleButton from './cancel-schedule-button'
import RecordScheduleButton from './schedule-button'

export default function RecordSchedule({ record, label="record", methods }) {

  return useMemo(() => {
    if (!record.publishDate || record.editingStatus === "Published") return null
    if (record?.schedule) return <RecordCancelScheduleButton record={record} label={label} methods={methods}/>
    if (!record?.schedule && record?.publishDate && new Date(record?.publishDate) > new Date()) return <RecordScheduleButton record={record} label={label} methods={methods}/>
    return (
      <div className="border-t border-gray-300 pt-4 space-y-4">
        <div className="text-sm">Select a future date in order to schedule this {label}.</div>
      </div>
    )
  }, [record, label, methods])
}
