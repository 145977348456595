import coverImageFragment from './cover-image'
import metaTagsFragment from './meta-tags'
import sectionsFragment from './sections'
import timestampsFragment from './timestamps'

export default `
  id
  titleDe
  titleEn
  slugDe
  slugEn
  teaserDe
  teaserEn
  publishDate
  advertisement
  preperationTime
  portionSize
  difficulty
  flavor
  soyFree
  withoutNuts
  glutenFree
  vgWortId
  categories {
    id
    titleDe
    editingStatus
  }
  coverImage {
    ${coverImageFragment}
  }
  author {
    id
    titleDe: displayName
  }
  ${timestampsFragment}
  ${metaTagsFragment}
  sections {
    ${sectionsFragment}
  }
  schedule {
    id
    runAt
    editor {
      id
      displayName
    }
  }
`
