import React, { useContext, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'

import Button from '../../components/button'
import DateTime from '../../components/date'
import { NoSymbolIcon } from '@heroicons/react/24/solid'
import NotificationContext from '../../components/notifications/context'
import scheduleRecordsFragment from '../../fragments/schedule-records'
import { withErrors } from '../../components/errors'

const CANCEL_SCHEDULE_RECORD_MUTATION = gql`
  mutation CancelRecordScheduleMutation($id: ID!) {
    cancelRecordSchedule(input: { id: $id }) {
      record {
        ${scheduleRecordsFragment}
      }
    }
  }
`

export default function RecordScheduleButton({ record, label="record", methods }) {
  const { addNotification } = useContext(NotificationContext)
  const [cancelSchedule, { loading: cancelScheduling }] = useMutation(CANCEL_SCHEDULE_RECORD_MUTATION, {
    onError: (err) => {
      addNotification({ title: 'Error canceling schedule', message: `The ${label} schedule could not be cancled.`, type: 'error' })
      withErrors(err).forEach(({ name, type, message }) =>
        methods.setError(name, { type, message })
      )
    },
    onCompleted: () => {
      addNotification({ title: 'Schedule canceled', message: `The ${label} schedule was successfuly canceled.`, type: 'success' })
    }
  })

  return useMemo(() => {
    return (
      <div className="border-t border-gray-300 pt-4 space-y-4">
        <Button
          title="Cancel Schedule"
          icon={<NoSymbolIcon className="w-5 h-5"/>}
          className="w-full"
          loading={cancelScheduling}
          onClick={() => cancelSchedule({ variables: { id: record.id }})}
        />
        { record?.schedule?.editor?.displayName && <div>Scheduled by {record?.schedule?.editor?.displayName}.</div> }
        <div className="text-sm">This recipe is scheduled to be published on <b><DateTime date={record?.schedule?.runAt}/></b> at <b><DateTime date={record?.schedule?.runAt} format="HH:mm"/></b>.</div>
      </div>
    )
  }, [record, cancelSchedule, cancelScheduling, label])
}
