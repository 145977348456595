import React from 'react'
import Fieldset from '../../form/fieldset'
import Input from '../../form/input'
import Tags from '../../form/tags'
import Textarea from '../../form/textarea'

export default function Metafields({ methods }) {
  return (
    <>
      <Fieldset>
        <Input
          label="Meta Title - German"
          name="metaTitleDe"
          register={methods.register}
          maxLength={60}
        />
        <Input
          label="Meta Title- English"
          name="metaTitleEn"
          register={methods.register}
          maxLength={60}
        />
      </Fieldset>
      <Fieldset>
        <Textarea
          label="Meta Description - German"
          name="metaDescriptionDe"
          control={methods.control}
        />
        <Textarea
          label="Meta Description - English"
          name="metaDescriptionEn"
          control={methods.control}
        />
      </Fieldset>
      <Fieldset>
        <Tags
          label="Keywords - German"
          name="keywordsDe"
          control={methods.control}
          help="Hit enter to create a keyword."
        />
        <Tags
          label="Keywords - English"
          name="keywordsEn"
          control={methods.control}
          help="Hit enter to create a keyword."
        />
      </Fieldset>
    </>
  )
}
