import React, { useCallback, useEffect, useRef, useState } from 'react'
import { classNames, slugify } from '../../helper'
import { useController, useFormState } from "react-hook-form"

import Label from './label'

export default function Tags({ label, name, control, help }) {
  const inputRef = useRef()
  const { errors } = useFormState()
  const { field: { onChange, value }} = useController({ name, control })
  const [localValue, setLocalValue] = useState()
  const error = errors[name]
  const hasError = !!error

  useEffect(() => {
    const val = value === null || value === undefined || value === "" ? [] : value?.split(",")
    setLocalValue(val)
  }, [value, setLocalValue])

  const onHandleChange = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      const newTags = [
        ...localValue,
        e.target.value
      ]

      setLocalValue(newTags)
      onChange(newTags.join(","))

      inputRef.current.value = ""
    }
  }

  const onRemove = tagToRemove => {
    const newState = [...localValue.filter(tag => tag !== tagToRemove)]
    setLocalValue(newState)
    onChange(newState.join(","))
  }

  return (
    <div className="space-y-2">
      { label && <Label label={label} htmlFor={name} hasError={hasError}/>}
      <input
        id={name}
        ref={inputRef}
        type="text"
        className={classNames(
          hasError ? "border-red-500 placeholder:text-red-300" : "border-gray-300",
          "block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        )}
        placeholder="Type here to add a keyword"
        onKeyDown={onHandleChange}
      />
      <TagBubbles tags={localValue} onRemove={onRemove}/>
      { help && <div className="text-sm text-gray-500">{help}</div> }
      { error && <div className="text-sm text-red-500">{error.message}</div> }
    </div>
  )
}

function TagBubbles({ tags=[], onRemove }) {
  return (
    <div className="space-x-2">
      {tags.map(tag => (
        <span key={tag} className="inline-flex items-center rounded-full bg-indigo-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-indigo-700">
          {tag}
          <button
            onClick={() => onRemove(tag)}
            type="button"
            className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none"
          >
            <span className="sr-only">Remove large option</span>
            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
          </button>
        </span>
      ))}
    </div>
  )
}
