import React, { useContext, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'

import Button from '../../components/button'
import { CalendarDaysIcon } from '@heroicons/react/24/solid'
import DateTime from '../../components/date'
import NotificationContext from '../../components/notifications/context'
import scheduleRecordsFragment from '../../fragments/schedule-records'
import { withErrors } from '../../components/errors'

const SCHEDULE_RECORD_MUTATION = gql`
  mutation ScheduleRecordMutation($id: ID!) {
    scheduleRecord(input: { id: $id }) {
      record {
        ${scheduleRecordsFragment}
      }
    }
  }
`

export default function RecordScheduleButton({ record, label="record", methods }) {
  const { addNotification } = useContext(NotificationContext)
  const [schedule, { loading: scheduling }] = useMutation(SCHEDULE_RECORD_MUTATION, {
    onError: (err) => {
      addNotification({ title: `Error scheduling ${label}`, message: `The ${label} could not be scheduled.`, type: 'error' })
      withErrors(err).forEach(({ name, type, message }) =>
        methods.setError(name, { type, message })
      )
    },
    onCompleted: () => {
      addNotification({ title: `Successfuly scheduled`, message: `The ${label} was successfuly scheduled.`, type: 'success' })
    }
  })

  return useMemo(() => {
    return (
      <div className="border-t border-gray-300 pt-4 space-y-4">
        <Button
          title="Schedule"
          icon={<CalendarDaysIcon className="w-5 h-5"/>}
          variant="indigo"
          className="w-full"
          loading={scheduling}
          onClick={() => schedule({ variables: { id: record.id }})}
        />
        <div className="text-sm">You can schedule this {label}. It will be published on <b><DateTime date={record?.publishDate}/></b> at <b><DateTime date={record?.publishDate} format="HH:mm"/></b>, if scheduled.</div>
      </div>
    )
  }, [record, schedule, scheduling, label])
}
