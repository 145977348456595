import { Controller, useFormState } from "react-hook-form"

import Label from './label'
import React from 'react'
import { classNames } from '../../helper'

export default function Integer({ label, control, name, type="text" }) {
  const { errors } = useFormState()
  const error = errors[name]
  const hasError = !!error

  return (
    <div className="space-y-2">
      { label && <Label label={label} htmlFor={name} hasError={hasError}/>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          return (
            <input
              id={name}
              name={name}
              type="number"
              value={value ?? ""}
              className={classNames(
                hasError ? "border-red-500 placeholder:text-red-300" : "border-gray-300",
                "block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              )}
              placeholder={label}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) event.preventDefault()
              }}
              onChange={({ target: { value: tmpValue }}) => {
                const newValue = tmpValue !== "" ? parseInt(tmpValue, 10) : null
                if (newValue !== value) onChange(newValue)
              }}
            />
          )
        }}
      />
      { error && <div className="text-sm text-red-500">{error.message}</div> }
    </div>
  )
}
