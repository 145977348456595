export default `
  ...on Recipe {
    id
    updatedAt
    schedule {
      id
      runAt
      editor {
        id
        displayName
      }
    }
  }
  ...on Post {
    id
    updatedAt
    schedule {
      id
      runAt
      editor {
        id
        displayName
      }
    }
  }
  ...on Page {
    id
    updatedAt
    schedule {
      id
      runAt
      editor {
        id
        displayName
      }
    }
  }
  ...on Category {
    id
    updatedAt
    schedule {
      id
      runAt
      editor {
        id
        displayName
      }
    }
  }
  ...on Series {
    id
    updatedAt
    schedule {
      id
      runAt
      editor {
        id
        displayName
      }
    }
  }
`
